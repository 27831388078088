import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "render-a-choropleth-map-of-the-us"
    }}>{`Render a choropleth map of the US`}</h1>
    <Vimeo id={429881355} mdxType="Vimeo" />
    <p>{`With our data in hand, it's time to draw some pictures. A choropleth map will
show us the best places to be in tech.`}</p>
    <p>{`We're showing the delta between median household salary in a statistical county
and the average salary of an individual tech worker on a visa. The darker the
blue, the higher the difference.`}</p>
    <p>{`The more a single salary can out-earn an entire household, the better off you
are.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/choropleth-map-shortened-dataset.png",
        "alt": "Choropleth map with shortened dataset"
      }}></img></p>
    <p>{`There's a lot of gray on this map because the shortened dataset doesn't have
that many counties. Full dataset is going to look better, I promise.`}</p>
    <p>{`Turns out immigration visa opportunities for techies aren't evenly distributed
throughout the country. Who knew?`}</p>
    <p>{`Just like before, we're going to start with changes in our `}<inlineCode parentName="p">{`App`}</inlineCode>{` component,
then build the new bit.`}</p>
    <h2 {...{
      "id": "step-1-prep-appjs"
    }}>{`Step 1: Prep App.js`}</h2>
    <Vimeo id={429881539} mdxType="Vimeo" />
    <p>{`You might guess the pattern already: add an import, add a helper method or two,
update `}<inlineCode parentName="p">{`render`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js
import Preloader from "./components/Preloader"
import { loadAllData } from "./DataHandling"

// Insert the line(s) between here...
import CountyMap from "./components/CountyMap"
// ...and here.
`}</code></pre>
    <p>{`That imports the `}<inlineCode parentName="p">{`CountyMap`}</inlineCode>{` component from `}<inlineCode parentName="p">{`components/CountyMap/`}</inlineCode>{`. Your
browser will show an error overlay about some file or another until we're done.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`App`}</inlineCode>{` class itself, we add a `}<inlineCode parentName="p">{`countyValue`}</inlineCode>{` method. It takes a county
entry and a map of tech salaries, and it returns the delta between median
household income and a single tech salary.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js
function countyValue(county, techSalariesMap) {
  const medianHousehold = medianIncomes[county.id],
    salaries = techSalariesMap[county.name]

  if (!medianHousehold || !salaries) {
    return null
  }

  const median = d3.median(salaries, (d) => d.base_salary)

  return {
    countyID: county.id,
    value: median - medianHousehold.medianIncome,
  }
}
`}</code></pre>
    <p>{`We use `}<inlineCode parentName="p">{`medianIncomes`}</inlineCode>{` to get the median household salary and the
`}<inlineCode parentName="p">{`techSalariesMap`}</inlineCode>{` input to get salaries for a specific census area. Then we use
`}<inlineCode parentName="p">{`d3.median`}</inlineCode>{` to calculate the median value for salaries and return a two-element
dictionary with the result.`}</p>
    <p><inlineCode parentName="p">{`countyID`}</inlineCode>{` specifies the county and `}<inlineCode parentName="p">{`value`}</inlineCode>{` is the delta that our choropleth
displays.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`render`}</inlineCode>{` method, we'll:`}</p>
    <ul>
      <li parentName="ul">{`prep a list of county values`}</li>
      <li parentName="ul">{`remove the "data loaded" indicator`}</li>
      <li parentName="ul">{`render the map`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js
render() {
    // ...
    if (techSalaries.length < 1) {
        return (
            <Preloader />
        );
    }

    // Insert the line(s) between here...
    const filteredSalaries = techSalaries,
          filteredSalariesMap = _.groupBy(filteredSalaries, 'countyID'),
          countyValues = countyNames.map(
              county => countyValue(county, filteredSalariesMap)
          ).filter(d => !_.isNull(d));

    let zoom = null;
    // ...and here.

    return (
      <div className="App container">
        <svg width="1100" height="500">
            <CountyMap usTopoJson={usTopoJson}
                       USstateNames={USstateNames}
                       values={countyValues}
                       x={0}
                       y={0}
                       width={500}
                       height={500}
                       zoom={zoom} />
        </svg>
      </div>
    );
}
`}</code></pre>
    <p>{`We call our dataset `}<inlineCode parentName="p">{`filteredTechSalaries`}</inlineCode>{` because we're going to add filtering in the `}<a parentName="p" {...{
        "href": "/tech-salaries/user-control"
      }}>{`subchapter about adding user controls`}</a>{`. Using the proper name now means less code to change later. The magic of foresight 😄`}</p>
    <p>{`We use `}<inlineCode parentName="p">{`_.groupBy`}</inlineCode>{` to build a dictionary mapping each `}<inlineCode parentName="p">{`countyID`}</inlineCode>{` to an array of
salaries, and we use our `}<inlineCode parentName="p">{`countyValue`}</inlineCode>{` method to build an array of counties for
our map.`}</p>
    <p>{`We set `}<inlineCode parentName="p">{`zoom`}</inlineCode>{` to `}<inlineCode parentName="p">{`null`}</inlineCode>{` for now. We'll use this later.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`return`}</inlineCode>{` statement, we remove our "data loaded" indicator, and add an
`}<inlineCode parentName="p">{`<svg>`}</inlineCode>{` element that's `}<inlineCode parentName="p">{`1100`}</inlineCode>{` pixels wide and `}<inlineCode parentName="p">{`500`}</inlineCode>{` pixels high. Inside, we
place the `}<inlineCode parentName="p">{`CountyMap`}</inlineCode>{` component with a bunch of properties. Some dataset stuff,
some sizing and positioning stuff.`}</p>
    <h3 {...{
      "id": "step-11-simplify-appjs-state"
    }}>{`Step 1.1: Simplify App.js state`}</h3>
    <Vimeo id={429881720} mdxType="Vimeo" />
    <p>{`We simplify state into a single object that holds all datasets. That way we can save some re-renders and make our life easier too.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

const [datasets, setDatasets] = useState({
  techSalaries: [],
  medianIncomes: [],
  countyNames: [],
  usTopoJson: null,
  USstateNames: null,
})

const {
  techSalaries,
  medianIncomes,
  countyNames,
  usTopoJson,
  USstateNames,
} = datasets

async function loadData() {
  const datasets = await loadAllData()
  setDatasets(datasets)
}
`}</code></pre>
    <h2 {...{
      "id": "step-3-countymapjs"
    }}>{`Step 3: CountyMap.js`}</h2>
    <Vimeo id={429881928} mdxType="Vimeo" />
    <p>{`Here comes the fun part - declaratively drawing a map. You'll see why I love
using React for dataviz.`}</p>
    <p>{`We're using the
`}<a parentName="p" {...{
        "href": "/building-blocks/3"
      }}>{`full-feature integration`}</a>{`
approach and a lot of D3 maps magic. Drawing a map with D3 I'm always surprised
how little code it takes.`}</p>
    <p>{`Start with the imports: React, D3, lodash, topojson, County component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/CountyMap.js
import React from "react"
import * as d3 from "d3"
import * as topojson from "topojson"
import _ from "lodash"
`}</code></pre>
    <p>{`Out of these, we haven't built `}<inlineCode parentName="p">{`County`}</inlineCode>{` yet, and you haven't seen `}<inlineCode parentName="p">{`topojson`}</inlineCode>{`
before.`}</p>
    <p>{`TopoJSON is a geographical data format based on JSON. We're using the
`}<inlineCode parentName="p">{`topojson`}</inlineCode>{` library to translate our geographical datasets into GeoJSON, which
is another way of defining geo data with JSON.`}</p>
    <p>{`I don't know why there are two, but TopoJSON produces smaller files, and
GeoJSON can be fed directly into D3's geo functions. ¯`}{`\\`}<em parentName="p">{`(ツ)`}</em>{`/¯`}</p>
    <p>{`Maybe it's a case of `}<a parentName="p" {...{
        "href": "https://xkcd.com/927/"
      }}>{`competing standards`}</a>{`.`}</p>
    <h3 {...{
      "id": "constructor"
    }}>{`Constructor`}</h3>
    <p>{`We stub out the `}<inlineCode parentName="p">{`CountyMap`}</inlineCode>{` component then fill it in with logic.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/CountyMap/CountyMap.js
const CountyMap = ({
    usTopoJson,
    USstateNames,
    x,
    y,
    width,
    height,
    zoom,
    values,
}) => {
        if (!usTopoJson) {
            return null;
        }else{
            return (

            );
        }
    }
}

export default CountyMap;
`}</code></pre>
    <p>{`We need three D3 objects to build a choropleth map: a geographical projection,
a path generator, and a quantize scale for colors.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/CountyMap.js
const projection = d3.geoAlbersUsa().scale(1280)
const geoPath = d3.geoPath().projection(projection)
const quantize = d3.scaleQuantize().range(d3.range(9))
`}</code></pre>
    <p>{`You might remember geographical projections from high school. They map a sphere
to a flat surface. We use `}<inlineCode parentName="p">{`geoAlbersUsa`}</inlineCode>{` because it's made specifically for
maps of the USA.`}</p>
    <p>{`D3 offers many other projections. You can see them on
`}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-geo#projections"
      }}>{`d3-geo's Github page`}</a>{`.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`geoPath`}</inlineCode>{` generator takes a projection and returns a function that generates
the `}<inlineCode parentName="p">{`d`}</inlineCode>{` attribute of `}<inlineCode parentName="p">{`<path>`}</inlineCode>{` elements. This is the most general way to specify
SVG shapes. I won't go into explaining the `}<inlineCode parentName="p">{`d`}</inlineCode>{` here, but it's
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d"
      }}>{`an entire language`}</a>{`
for describing shapes.`}</p>
    <p><inlineCode parentName="p">{`quantize`}</inlineCode>{` is a D3 scale. We've talked about the basics of scales in the
`}<a parentName="p" {...{
        "href": "/building-blocks/3"
      }}>{`D3 Axis example`}</a>{`.
This one splits a domain into 9 quantiles and assigns them specific values from
the `}<inlineCode parentName="p">{`range`}</inlineCode>{`.`}</p>
    <p>{`Let's say our domain goes from 0 to 90. Calling the scale with any number
between 0 and 9 would return 1. 10 to 19 returns 2 and so on. We'll use it to
pick colors from an array.`}</p>
    <h3 {...{
      "id": "the-d3-magic-sauce"
    }}>{`the D3 magic sauce`}</h3>
    <Vimeo id={429882091} mdxType="Vimeo" />
    <p>{`Keeping our geo path and quantize scale up to date is simple, but we'll make it
harder by adding a zoom feature. It won't work until we build the filtering,
but hey, we'll already have it by then! 😄`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/CountyMap.js
const projection = d3
  .geoAlbersUsa()
  .scale(1280)
  .translate([width / 2, height / 2])
  .scale(width * 1.3)
const geoPath = d3.geoPath().projection(projection)
const quantize = d3.scaleQuantize().range(d3.range(9))

if (zoom && usTopoJson) {
  const us = usTopoJson,
    USstatePaths = topojson.feature(us, us.objects.states).features,
    id = _.find(USstateNames, { code: zoom }).id

  projection.scale(width * 4.5)

  const centroid = geoPath.centroid(_.find(USstatePaths, { id: id })),
    translate = projection.translate()

  projection.translate([
    translate[0] - centroid[0] + width / 2,
    translate[1] - centroid[1] + height / 2,
  ])
}

if (values) {
  quantize.domain([
    d3.quantile(values, 0.15, (d) => d.value),
    d3.quantile(values, 0.85, (d) => d.value),
  ])
}
`}</code></pre>
    <p>{`There's a lot going on here.`}</p>
    <p>{`We destructure `}<inlineCode parentName="p">{`projection`}</inlineCode>{`, `}<inlineCode parentName="p">{`quantize`}</inlineCode>{`, and `}<inlineCode parentName="p">{`geoPath`}</inlineCode>{` out of component state.
These are the D3 object we're about to update.`}</p>
    <p>{`First up is the projection. We translate (move) it to the center of our drawing
area and set the scale property. You have to play around with this value until
you get a nice result because it's different for every projection.`}</p>
    <p>{`Then we do some weird stuff if `}<inlineCode parentName="p">{`zoom`}</inlineCode>{` is defined.`}</p>
    <p>{`We get the list of all US state features in our geo data, find the one we're
`}<inlineCode parentName="p">{`zoom`}</inlineCode>{`-ing on, and use the `}<inlineCode parentName="p">{`geoPath.centroid`}</inlineCode>{` method to calculate its center
point. This gives us a new coordinate to `}<inlineCode parentName="p">{`translate`}</inlineCode>{` our projection onto.`}</p>
    <p>{`The calculation in `}<inlineCode parentName="p">{`.translate()`}</inlineCode>{` helps us align the center point of our `}<inlineCode parentName="p">{`zoom`}</inlineCode>{`
US state with the center of the drawing area.`}</p>
    <p>{`While all of this is going on, we also tweak the `}<inlineCode parentName="p">{`.scale`}</inlineCode>{` property to make the
map bigger. This creates a zooming effect.`}</p>
    <p>{`After all that, we update the quantize scale's domain with new values. Using
`}<inlineCode parentName="p">{`d3.quantile`}</inlineCode>{` lets us offset the scale to produce a more interesting map.
Again, I discovered these values through experiment - they cut off the top and
bottom of the range because there isn't much there. This brings higher contrast
to the richer middle of the range.`}</p>
    <h3 {...{
      "id": "render"
    }}>{`render`}</h3>
    <Vimeo id={429882190} mdxType="Vimeo" />
    <p>{`After all that hard work, rendering is a breeze. We prep our data
then loop through it and render a `}<inlineCode parentName="p">{`County`}</inlineCode>{` element for each entry.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/CountyMap.js
if (!usTopoJson) {
  return null
} else {
  const us = usTopoJson,
    USstatesMesh = topojson.mesh(us, us.objects.states, (a, b) => a !== b),
    counties = topojson.feature(us, us.objects.counties).features

  const countyValueMap = _.fromPairs(values.map((d) => [d.countyID, d.value]))

  return (
    <g>
      {counties.map((feature) => (
        <County
          geoPath={geoPath}
          feature={feature}
          zoom={zoom}
          key={feature.id}
          quantize={quantize}
          value={countyValueMap[feature.id]}
        />
      ))}
      <path
        d={geoPath(USstatesMesh)}
        style={{
          fill: "none",
          stroke: "#fff",
          strokeLinejoin: "round",
        }}
      />
    </g>
  )
}
`}</code></pre>
    <p>{`We use the TopoJSON library to grab data out of the `}<inlineCode parentName="p">{`usTopoJson`}</inlineCode>{` dataset.`}</p>
    <p><inlineCode parentName="p">{`.mesh`}</inlineCode>{` calculates a mesh for US states – a thin line around the edges.
`}<inlineCode parentName="p">{`.feature`}</inlineCode>{` calculates feature for each count – fill in with color.`}</p>
    <p>{`Mesh and feature aren't tied to US states or counties by the way. It's just a
matter of what you get back: borders or flat areas. What you need depends on
what you're building.`}</p>
    <p>{`We use Lodash's `}<inlineCode parentName="p">{`_.fromPairs`}</inlineCode>{` to build a dictionary that maps county
identifiers to their values. Building it beforehand makes our code faster. You
can read some details about the speed optimizations
`}<a parentName="p" {...{
        "href": "https://swizec.com/blog/optimizing-react-choropleth-map-rendering/swizec/7302"
      }}>{`here`}</a>{`.`}</p>
    <p>{`As promised, the `}<inlineCode parentName="p">{`return`}</inlineCode>{` statement loops through the list of `}<inlineCode parentName="p">{`counties`}</inlineCode>{` and
renders `}<inlineCode parentName="p">{`County`}</inlineCode>{` components. Each gets a bunch of attributes and returns a
`}<inlineCode parentName="p">{`<path>`}</inlineCode>{` element that looks like a specific county.`}</p>
    <p>{`For US state borders, we render a single `}<inlineCode parentName="p">{`<path>`}</inlineCode>{` element and use `}<inlineCode parentName="p">{`geoPath`}</inlineCode>{` to
generate the `}<inlineCode parentName="p">{`d`}</inlineCode>{` attribute.`}</p>
    <h2 {...{
      "id": "step-4-county-component"
    }}>{`Step 4: County component`}</h2>
    <Vimeo id={429882348} mdxType="Vimeo" />
    <p>{`The `}<inlineCode parentName="p">{`County`}</inlineCode>{` component is built from two parts: imports and color constants,
and a component that returns a `}<inlineCode parentName="p">{`<path>`}</inlineCode>{`. All the hard calculation happens in
`}<inlineCode parentName="p">{`CountyMap`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/CountyMap.js

const ChoroplethColors = _.reverse([
  "rgb(247,251,255)",
  "rgb(222,235,247)",
  "rgb(198,219,239)",
  "rgb(158,202,225)",
  "rgb(107,174,214)",
  "rgb(66,146,198)",
  "rgb(33,113,181)",
  "rgb(8,81,156)",
  "rgb(8,48,107)",
])

const BlankColor = "rgb(240,240,240)"
`}</code></pre>
    <p>{`We import React and Lodash, and define some color constants. I got the
`}<inlineCode parentName="p">{`ChoroplethColors`}</inlineCode>{` from some example online, and `}<inlineCode parentName="p">{`BlankColor`}</inlineCode>{` is a pleasant
gray.`}</p>
    <p>{`Now we need the `}<inlineCode parentName="p">{`County`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/CountyMap.js
const County = ({ geoPath, feature, zoom, key, quantize, value }) => {
  let color = BlankColor

  if (value) {
    color = ChoroplethColors[quantize(value)]
  }

  return (
    <path d={geoPath(feature)} style={{ fill: color }} title={feature.id} />
  )
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`render`}</inlineCode>{` method uses a `}<inlineCode parentName="p">{`quantize`}</inlineCode>{` scale to pick the right color and returns
a `}<inlineCode parentName="p">{`<path>`}</inlineCode>{` element. `}<inlineCode parentName="p">{`geoPath`}</inlineCode>{` generates the `}<inlineCode parentName="p">{`d`}</inlineCode>{` attribute, we set style to
`}<inlineCode parentName="p">{`fill`}</inlineCode>{` the color, and we give our path a `}<inlineCode parentName="p">{`title`}</inlineCode>{`.`}</p>
    <p>{`Your browser should now show a map.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/choropleth-map-shortened-dataset.png",
        "alt": "Choropleth map with shortened dataset"
      }}></img></p>
    <p>{`Tech work visas just aren't that evenly distributed. Even with the full dataset
most counties are gray.`}</p>
    <p>{`If that didn't work, consult
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/reactdataviz-project/commit/ccb45126ce130d8456ee2f6e8a5ff3b21258e1d5"
      }}>{`this diff on Github`}</a>{`.`}</p>
    <h2 {...{
      "id": "step-5-optimize-d3-code-with-custom-hooks"
    }}>{`Step 5: optimize D3 code with custom hooks`}</h2>
    <Vimeo id={429882695} mdxType="Vimeo" />
    <p>{`Our `}<inlineCode parentName="p">{`CountyMap`}</inlineCode>{` component got quite messy with all that D3 logic in there. We can clean it up with custom hooks.`}</p>
    <p>{`The goal is to extract logic into self-contained custom hooks that return the end result we're looking for. Logic in the function, do the math, return the final D3 object.`}</p>
    <p>{`Remember: any function that uses hooks is a hook. We prefix them with `}<inlineCode parentName="p">{`use`}</inlineCode>{` out of convention.`}</p>
    <p>{`While we're at it we can also wrap the code in `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` so our code runs faster. `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` ensures we recreate the objects only when something relevant changes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/CountyMap.js

function useQuantize(values) {
  return useMemo(() => {
    const scale = d3.scaleQuantize().range(d3.range(9))

    if (values) {
      scale.domain([
        d3.quantile(values, 0.15, (d) => d.value),
        d3.quantile(values, 0.85, (d) => d.value),
      ])
    }

    return scale
  }, [values])
}

function useProjection({ width, height, zoom, usTopoJson, USstateNames }) {
  return useMemo(() => {
    const projection = d3
      .geoAlbersUsa()
      .scale(1280)
      .translate([width / 2, height / 2])
      .scale(width * 1.3)
    const geoPath = d3.geoPath().projection(projection)

    if (zoom && usTopoJson) {
      const us = usTopoJson,
        USstatePaths = topojson.feature(us, us.objects.states).features,
        id = _.find(USstateNames, { code: zoom }).id

      projection.scale(width * 4.5)

      const centroid = geoPath.centroid(_.find(USstatePaths, { id: id })),
        translate = projection.translate()

      projection.translate([
        translate[0] - centroid[0] + width / 2,
        translate[1] - centroid[1] + height / 2,
      ])
    }

    return geoPath
  }, [width, height, zoom, usTopoJson, USstateNames])
}
`}</code></pre>
    <p>{`You can then use them as any other hook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/CountyMap.js
const CountyMap = ({
    usTopoJson,
    USstateNames,
    x,
    y,
    width,
    height,
    zoom,
    values,
}) => {
    const geoPath = useProjection({
        width,
        height,
        zoom,
        usTopoJson,
        USstateNames,
    });
    const quantize = useQuantize(values);
`}</code></pre>
    <p>{`If that doesn't work, check out `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/reactdataviz-project/commit/b5f073216cf8c3f5e2f024584d1acb97bb0c143d"
      }}>{`this diff on GitHub`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      